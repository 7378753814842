import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { isHighOrJuniorHighSchool } from '~/utils'
import isJapanese from '~/utils/isJapanese'
import {
  trainingSportJapaneseTxtWithEnduranceRun,
  trainingSportJapaneseTxtWithoutEnduranceRun,
} from '~/utils/test-const'
import '../../lib/assets/training.css'
import styles from '../../lib/styles/training.module.scss'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'
import { BallThrow } from '../training-tabs/ball-throw'
import { EnduranceRun } from '../training-tabs/endurance-run'
import { Grip } from '../training-tabs/grip'
import { LongMetersRun } from '../training-tabs/long-meters-run'
import { LongSitting } from '../training-tabs/long-sitting'
import { ShortMetersRun } from '../training-tabs/short-meters-run'
import { SideJump } from '../training-tabs/side-jump'
import { SitUp } from '../training-tabs/sit-up'
import { StandingLongJump } from '../training-tabs/standing-long-jump'
import { ITrainingTab } from './training/types/tabs'

const tabScreens = [
  {
    key: 'GRIP_STRENGTH',
    Component: Grip,
    _id: '',
  },
  {
    key: 'SIT_UPS',
    Component: SitUp,
    _id: '',
  },
  {
    key: 'BENDING',
    Component: LongSitting,
    _id: '',
  },
  {
    key: 'SIDE_JUMP',
    Component: SideJump,
    _id: '',
  },
  {
    key: 'SHUTTLE_RUN',
    Component: ShortMetersRun,
    _id: '',
  },
  {
    key: 'ENDURANCE_RUN',
    Component: EnduranceRun,
    _id: '',
  },
  {
    key: 'SPRINT_RUN',
    Component: LongMetersRun,
    _id: '',
  },
  {
    key: 'STANDING_JUMP',
    Component: StandingLongJump,
    _id: '',
  },
  {
    key: 'HANDBALL_THROW',
    Component: BallThrow,
    _id: '',
  },
] as const

/**
 * Path: /training
 */
const TrainingPage = () => {
  const auth = useContext(AuthContext)
  const { schoolCategoryCode, me } = useAlphaStore()
  const [tabs, setTabs] = useState<ITrainingTab[]>([])

  const { t, i18n } = useTranslation()

  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp

  const currentTitles = {
    GRIP_STRENGTH: (
      <ruby>
        <span style={{ paddingLeft: 10, paddingRight: 10 }}>{t('握力')}</span>
        {isUsingJpRuby ? (
          <rt className="font-normal text-14-hina">あくりょく</rt>
        ) : null}
      </ruby>
    ),
    SIT_UPS: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>上体起</span>
          <rt className="font-normal text-14-hina">じょうたいお</rt>
        </ruby>
        こし
      </span>
    ) : (
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
        {t('上体起こし')}
      </span>
    ),
    BENDING: isUsingJpRuby ? (
      <ruby>
        <span>長座体前屈</span>
        <rt className="font-normal text-14-hina">ちょうざたいぜんくつ</rt>
      </ruby>
    ) : (
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
        {t('長座体前屈')}
      </span>
    ),
    SIDE_JUMP: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>反復横</span>
          <rt className="font-normal text-14-hina">はんぷくよこ</rt>
        </ruby>
        とび
      </span>
    ) : (
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
        {t('反復横跳び')}
      </span>
    ),
    SPRINT_RUN: isUsingJpRuby ? (
      <span>
        50m
        <ruby>
          <span>走</span>
          <rt className="font-normal text-14-hina">そう</rt>
        </ruby>
      </span>
    ) : (
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>{t('50m走')}</span>
    ),
    STANDING_JUMP: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>立</span>
          <rt className="font-normal text-14-hina">た</rt>
        </ruby>
        ち
        <ruby>
          <span>幅</span>
          <rt className="font-normal text-14-hina">はば</rt>
        </ruby>
        とび
      </span>
    ) : (
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
        {t('立ち幅跳び')}
      </span>
    ),
    HANDBALL_THROW: isUsingJpRuby ? (
      <span>
        ボール
        <ruby>
          <span>投</span>
          <rt className="font-normal text-14-hina">な</rt>
        </ruby>
        げ
      </span>
    ) : (
      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
        {t('ボール投げ')}
      </span>
    ),
    ENDURANCE_RUN: <span>{t('持久走')}</span>,
    SHUTTLE_RUN: <span>{t('シャトルラン')}</span>,
  }

  useEffect(() => {
    const tabs = document.querySelectorAll<HTMLElement>('.ant-tabs-tab-btn')
    tabs.forEach((tab) => {
      if (tab.innerHTML === '20メートル シャトルラン') {
        tab.style.fontSize = '14px' // eslint-disable-line no-param-reassign
        tab.style.whiteSpace = 'break-spaces' // eslint-disable-line no-param-reassign
        tab.style.textAlign = 'center' // eslint-disable-line no-param-reassign
        tab.style.padding = '0 38px' // eslint-disable-line no-param-reassign
      }
    })
  })

  const getDataTraining = (userId: string) => {
    const tabComponents: ITrainingTab[] = []
    let names = trainingSportJapaneseTxtWithoutEnduranceRun

    if (schoolCategoryCode && isHighOrJuniorHighSchool(schoolCategoryCode)) {
      names = trainingSportJapaneseTxtWithEnduranceRun
    }

    for (const tabItem of tabScreens) {
      if (names[tabItem.key]) {
        // @ts-ignore
        tabComponents.push({
          ...tabItem,
          name: isUsingJpRuby ? currentTitles[tabItem.key] : names[tabItem.key],
          _id: userId,
        })
      }
    }

    setTabs(tabComponents)
    if (tabComponents.length) {
      setItemActive(tabComponents[0].key)
    }
  }

  useEffect(() => {
    if (me) {
      getDataTraining(me._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  const [itemActive, setItemActive] = useState<string | null>(null)

  return (
    <Dashboard
      selectedMenu={TabIndex.Tests}
      navbar={<div className="theme-nav-title">{t('トレーニング')}</div>}
    >
      <div className="trainingWrapperForm">
        <div className="grid grid-cols-4 list-exam" style={{ marginTop: 18 }}>
          {tabs.map((item) => (
            <div
              key={item.key}
              className={
                (item.key === 'SHUTTLE_RUN' && isUsingJp ? 'font-run ' : '') +
                (itemActive === item.key ? 'exam-active' : '')
              }
              onClick={() => setItemActive(item.key)}
            >
              {isUsingJpRuby ? currentTitles[item.key] : t(item.name as string)}
            </div>
          ))}
        </div>
        <div
          className={styles.tabBar}
          style={{ padding: 12, background: '#F5F5F5' }}
        >
          {tabs.map((Item) => (
            <div
              key={Item.key}
              className={itemActive === Item.key ? '' : 'hidden'}
            >
              <Item.Component
                tabKey={Item.key}
                isUsingJpRuby={isUsingJpRuby}
                userId={Item._id}
              />
            </div>
          ))}
        </div>
      </div>
    </Dashboard>
  )
}

export default TrainingPage
