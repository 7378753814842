import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

export const Rt = ({ children, className }: Props) => {
  return <rt className={className}>{children}</rt>
}
