import { TFunction } from 'i18next'
import { isNumVal } from '~/utils/number'
import { ITestValResult } from '~/utils/types/api/alpha/test-result'

export const showEnduranceRunMinSec = (
  lastYearStudent: ITestValResult,
  t: TFunction<'translation', undefined>,
) => {
  const obj = lastYearStudent.enduranceRun

  return obj && (isNumVal(obj.runningTimeSeconds) || isNumVal(obj.runningTime))
    ? `${obj.runningTime ?? '0'}${t('分')} ${obj.runningTimeSeconds ?? '0'}${t(
        '秒',
      )}`
    : `-${t('分')} -${t('秒')}`
}
