/**
 * @example
 * "q2"
 */
export const getFirstUnansweredQuestionKey = (
  answeredQuestions: Record<string, number | string>,
  isOptionalQuestions: boolean[] | undefined,
): string | undefined => {
  if (isOptionalQuestions?.length) {
    for (const [key, value] of Object.entries(answeredQuestions)) {
      const questionnaireIndex = Number(key.slice(1)) - 1
      const isOptional = isOptionalQuestions[Number(key.slice(1)) - 1]

      if (
        (value === undefined || value === null) &&
        !isOptional &&
        questionnaireIndex < isOptionalQuestions.length
      ) {
        return key
      }
    }
  } else {
    for (const [key, value] of Object.entries(answeredQuestions)) {
      if (value === undefined || value === null) {
        return key
      }
    }
  }
}
