import './RightDropdown.css'

import { CaretDownOutlined, GlobalOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_LANGUAGE_KEY, TLang } from '~/utils/constants'
import { displayFairNav, displayNoneFairNav } from '~/utils/ele'
import isJapanese from '~/utils/isJapanese'

type RightDropdownProps = {
  isUsingJpRuby: boolean
}

const RightDropdown: FunctionComponent<RightDropdownProps> = ({
  isUsingJpRuby,
}) => {
  const { i18n, t } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const changeLanguageHandler = (lang: TLang) => {
    i18n.changeLanguage(lang)
    window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, lang)
  }

  const menu = (
    <Menu className="">
      <Menu.Item
        className={`text-base font-bold ${isUsingJp ? 'bg-blue-100' : ''}`}
        key="j"
        onClick={() => {
          if (isUsingJp) return
          changeLanguageHandler('ja')
          displayFairNav()
        }}
      >
        {t('日本語 (Japanese)')}
      </Menu.Item>

      <Menu.Item
        className={`text-base font-bold ${!isUsingJp ? 'bg-blue-100' : ''}`}
        key="e"
        onClick={() => {
          if (!isUsingJp) return
          changeLanguageHandler('en')
          displayNoneFairNav()
        }}
      >
        {t('英語 (English)')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} className="mr-4">
      <Space>
        <div className="hover:text-blue-100 cursor-pointer sm-dropdown-container display-pc">
          {isUsingJpRuby ? (
            <ruby>
              <span>言語</span>
              <rt className="text-12-hina font-normal">げんご</rt>
              &nbsp;(Language)
            </ruby>
          ) : (
            t('言語 (Language)')
          )}
        </div>

        <GlobalOutlined className="display-sp" />

        <CaretDownOutlined className="lang-dropdown-icon" />
      </Space>
    </Dropdown>
  )
}

export default RightDropdown
