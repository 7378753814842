import { Rule } from 'antd/lib/form'
import { TFunction } from 'i18next'
import { Rt } from '~/components/common/Rt'

export const getMinMaxMess = (min: number, max: number) => (
  <>
    {min}から{max}の
    <ruby>
      数字
      <Rt className="text-10px">すうじ</Rt>
    </ruby>
    を
    <ruby>
      入力して
      <Rt className="text-10px">にゅうりょく</Rt>
    </ruby>
    ください
  </>
)

export const getOneDecimalPlaceRule = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
) => ({
  pattern: /^\d+(?:\.\d{1})?$/,
  message: isUsingJpRuby ? (
    <>
      <ruby>
        小数点
        <Rt className="text-10px">しょうすうてん</Rt>
      </ruby>
      の
      <ruby>
        入力は
        <Rt className="text-10px">にゅうりょく</Rt>
      </ruby>
      <ruby>
        第1位
        <Rt className="text-10px">だいいちい</Rt>
      </ruby>
      までです
    </>
  ) : (
    t('小数点の入力は第1位までです')
  ),
})

export const integerRule = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
) => ({
  pattern: /^\d+$/,
  message: isUsingJpRuby ? (
    <>
      <ruby>
        小数点
        <Rt className="text-10px">しょうすうてん</Rt>
      </ruby>
      の
      <ruby>
        入力は
        <Rt className="text-10px">にゅうりょく</Rt>
      </ruby>
      できません
    </>
  ) : (
    t('小数点の入力はできません')
  ),
})

export const weightRules = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
): Rule[] => [
  {
    type: 'number',
    min: 10,
    max: 150,
    message: isUsingJpRuby
      ? getMinMaxMess(10, 150)
      : t('{{min}}から{{max}}の数字を入力してください', {
          min: 10,
          max: 150,
        }),
  },
  getOneDecimalPlaceRule(t, isUsingJpRuby),
]

export const heightRules = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
): Rule[] => [
  {
    type: 'number',
    min: 80,
    max: 220,
    message: isUsingJpRuby
      ? getMinMaxMess(80, 220)
      : t('{{min}}から{{max}}の数字を入力してください', {
          min: 80,
          max: 220,
        }),
  },
  getOneDecimalPlaceRule(t, isUsingJpRuby),
]
