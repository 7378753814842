import { TTestResultWithPoint } from './points'
import {
  testKeysHasShuttleRunOnly,
  testKeysWithoutShuttleRunEnduranceRun,
} from './test-const'
import { isTestResultNotExist } from './test-result'

export const checkIfIsValidRank = (
  testResult: TTestResultWithPoint | undefined | null,
  hasEnduranceRun: boolean,
): boolean => {
  if (!testResult) return false

  let tests:
    | typeof testKeysHasShuttleRunOnly
    | typeof testKeysWithoutShuttleRunEnduranceRun = testKeysHasShuttleRunOnly

  if (hasEnduranceRun) {
    // enduranceRun or shuttleRun is inputted or not
    if (
      isTestResultNotExist(testResult.enduranceRun) &&
      isTestResultNotExist(testResult.shuttleRun)
    )
      return false

    tests = testKeysWithoutShuttleRunEnduranceRun
  }

  for (const key of tests) {
    if (isTestResultNotExist(testResult[key])) return false
  }
  return true
}
