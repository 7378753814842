/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { ReactNode } from 'react'

type Props = {
  url: string
  children: ReactNode
}

const ExternalLink = ({ url, children }: Props) => {
  return (
    // biome-ignore lint/a11y/useValidAnchor: <explanation>
    <a
      href="javascript:void(0)"
      className="font-normal text-underline"
      onClick={() => window.open(url, '_blank')}
    >
      {children}
    </a>
  )
}

export default ExternalLink
