import { useEffect, useRef, useState } from 'react'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import { clearStorageAndAuthToken } from '~/student-utils/storages'
import Api from '~/utils/api'
import { CommonError } from '~/utils/error'
import { IStudentMe } from '~/utils/types/api/students'

export const useAuthApiGet = <TResponse = any>(
  apiUrl: string,
): {
  data: TResponse | undefined
  loading: boolean
  error: CommonError | undefined
} => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  const [data, setData] = useState<TResponse | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<CommonError | undefined>()

  const fetchedRef = useRef(false)

  useEffect(() => {
    if (authToken && !fetchedRef.current) {
      // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
      new Promise(async () => {
        try {
          setLoading(true)
          const { data }: { data: TResponse } = await Api.get(apiUrl)
          setData(data)
          setError(undefined)
        } catch (err: any) {
          console.error(`apiUrl: ${apiUrl} - error:`, err)

          if (apiUrl.includes('/me') && err.response?.status === 401) {
            clearStorageAndAuthToken()

            // keep this log
            console.warn(
              'When response status is unauthorized (401) at useAuthApiGet, logout!',
            )
          }

          setError(err)
        } finally {
          fetchedRef.current = false
          setLoading(false)
        }
      })
    } else {
      // keep this log
      console.log(
        'Not found auth token and fetchedRef.current is true when useAuthApiGet, do nothing!',
      )
      setLoading(false)
      setError(undefined)
    }
  }, [apiUrl, authToken])

  return {
    data: authToken ? data : undefined,
    loading,
    error,
  }
}

// Alpha student apis
export const useStudentMeApiQuery = () =>
  useAuthApiGet<IStudentMe>('/alpha/v1/student/me')

export const useStudentQuestionnaireResultApiQuery = () =>
  useAuthApiGet('/alpha/v1/student/survey')
