export const currentTestsImage = {
  height: '/images/height_icon.png',
  weight: '/images/weight_icon.png',
  grip: '/images/grip_icon.png',
  sitUps: '/images/sit_up_icon.png',
  bending: '/images/bending_icon.png',
  sideJump: '/images/side_jump_icon.png',
  shuttleRun: '/images/shuttle_run_icon.png',
  enduranceRun: '/images/shuttle_run_icon.png',
  sprintRun: '/images/sprint_run_icon.png',
  standingJump: '/images/standing_jump_icon.png',
  handballThrow: '/images/handball_throw_icon.png',
} as const
